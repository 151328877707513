import React, { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { FullScreenLoader } from "@app.automotus.io/components/FullScreenLoader";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { SignUpStepper, SignUpStepValue } from "@app.automotus.io/components/scenes/SignUp/SignUpStepper/SignUpStepper";
import { useProfileWithCompletedSteps } from "@app.automotus.io/components/hooks";
import OnboardingLayout from "@app.automotus.io/components/Layout/OnboardingLayout";
import LocationSelectionDialog from "@app.automotus.io/components/LocationSelectionDialog";
import { CITY_OPTIONS } from "common/constants";
import { useActivePayee } from "@app.automotus.io/components/hooks/useActivePayee";

/** Props passed to the {@link SignUp} component */
export interface SignUpProps {
  /** Indicates whether it's BillByMail sign-up flow */
  isBillByMail?: boolean;
}

/**
 * Parent route for all other signup routes
 */
export const SignUp: React.FC<SignUpProps> = () => {
  const navigate = useNavigate();
  const matchStep = useMatch("/signup/:activeStep");

  const { userProfile, loading, completedSteps } = useProfileWithCompletedSteps();
  const activeStep: SignUpStepValue = (matchStep?.params.activeStep as SignUpStepValue) || "profile";

  const handleClickStep = (step: SignUpStepValue) => {
    navigate(`/signup/${step}`);
  };

  const { activePayee, payeeSelectedByUser, error: activePayeeError, setActivePayee } = useActivePayee();
  const [defaultLocation, setDefaultLocation] = useState<string>(activePayee?.cityName || "");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (activePayee) {
      setDefaultLocation(activePayee.cityName);
    }
    if (!payeeSelectedByUser) {
      // When the user explicitly sets the payee account ID, we won't prompt them further. However, if the payee account
      // simply defaults to some localized value, we will return an indication as such.
      setDialogOpen(true);
    }
  }, [defaultLocation, activePayee, activePayeeError, payeeSelectedByUser]);

  useEffect(() => {
    if (activePayeeError) {
      console.error("failed to determine active payee", activePayeeError);
    }
  }, [activePayeeError]);

  const handleSelectLocation = ({ value }: { value: string }): void => {
    // TODO: need to use city of invoice
    const city = CITY_OPTIONS.find((item) => item.value === value);
    if (!city) {
      console.error(`failed to find city ${value}`);
      return;
    }
    setActivePayee({
      cityName: city.value,
      payeeAccountId: city.payeeAccountId,
    });
    setDialogOpen(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  if (loading || !userProfile) {
    return <FullScreenLoader loading={true} />;
  }

  return (
    <OnboardingLayout marginTopSmall={59} marginTopMedium={63} paddingHorizontal={0}>
      <Box sx={{ px: 2, mt: -2 }}>
        <SignUpStepper
          accountRoleType={userProfile.account.roleType || "payer"}
          completedSteps={completedSteps}
          activeStep={activeStep.toString() === "terms-of-service" ? "payment" : activeStep}
          onClickStep={handleClickStep}
        />
      </Box>
      <Divider />
      <LocationSelectionDialog
        defaultValue={defaultLocation}
        onClose={handleDialogClose}
        onSubmit={handleSelectLocation}
        open={dialogOpen}
        paperProps={{
          sx: {
            boxShadow:
              "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
            width: "290px",
            borderRadius: 1,
          },
        }}
      />
    </OnboardingLayout>
  );
};

export default SignUp;
