import { Position } from "../helpers";

export const CITY_OPTIONS: CityOption[] = [
  {
    value: "omaha",
    label: "Omaha, NE",
    lat: 41.256538,
    long: -95.934502,
    payeeAccountId: process.env.REACT_APP_OMAHA_ACCOUNT_ID || "",
    allowUserSelection: true,
  },
  {
    value: "pittsburgh",
    label: "Pittsburgh, PA",
    lat: 40.440624,
    long: -79.995888,
    payeeAccountId: process.env.REACT_APP_PITTSBURGH_ACCOUNT_ID || "",
    allowUserSelection: true,
  },
  {
    value: "bethlehem",
    label: "Bethlehem, PA",
    lat: 40.62638821134342,
    long: -75.37046807878374,
    payeeAccountId: process.env.REACT_APP_BETHLEHEM_ACCOUNT_ID || "",
    allowUserSelection: true,
  },
  {
    value: "fortlee",
    label: "Fort Lee, NJ",
    lat: 40.851959,
    long: -73.96893,
    payeeAccountId: process.env.REACT_APP_FORT_LEE_ACCOUNT_ID || "",
    allowUserSelection: true,
  },
  {
    value: "gsp-airport",
    label: "GSP Airport",
    lat: 34.89573152197923,
    long: -82.21683194682056,
    payeeAccountId: process.env.REACT_APP_GSP_AIRPORT_ACCOUNT_ID || "",
    allowUserSelection: true,
  },
];

export interface CityOption extends Position {
  value: string;
  label: string;
  payeeAccountId: string;
  /** Determines if the user can select this city/location. */
  allowUserSelection: boolean;
}

export const phoneNumberRegex =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
export type AppName = "app.automotus.io" | "curbpass.io" | "curbsuite.io";

export const appName: AppName = (process.env.REACT_APP_APP_NAME || "").includes("automotus.io")
  ? "app.automotus.io"
  : (process.env.REACT_APP_APP_NAME || "").includes("curbsuite.io")
  ? "curbsuite.io"
  : "curbpass.io";
